import React from "react"
import { graphql } from "gatsby"

import GlobalStateProvider from "src/context/provider"
import Layout from "components/layout"
import SEO from "components/seo"
import Hero from "components/sections/corporates/hero"
import Clients from "components/sections/corporates/clients"
import UseCases from "components/sections/corporates/useCases"
import Workflows from "components/sections/corporates/workflows"
import Security from "components/sections/common/security"
import Featured from "components/sections/corporates/featured"
import Pipeline from "components/sections/corporates/pipeline"
import Unify from "components/sections/corporates/steps/unify"
import Automate from "components/sections/corporates/steps/automate"
import Power from "components/sections/corporates/steps/power"
import { seoTitleSuffix } from "config"

const Corporates = ({ data }) => {
  const { frontmatter } = data.corporates.edges[0].node
  const globalState = {
    // if useSplashScreen=false, we skip the intro by setting isIntroDone=true
    isIntroDone: frontmatter.useSplashScreen ? false : true,
    // darkMode is initially disabled, a hook inside the Layout component
    // will check the user's preferences and switch to dark mode if needed
    darkMode: false,
  }

  return (
    <GlobalStateProvider initialState={globalState}>
      <Layout headerContent={data.header.edges} footerContent={data.footer.edges} lang="fr">
        <SEO
          frontmatter={frontmatter}
          lang="fr"
        />
        <Hero content={data.hero.edges} />
        <Clients  content={data.clients.edges} />
        <Pipeline />
        <Unify content={data.unify.edges} />
        <Automate content={data.automate.edges} />
        <Power content={data.power.edges} />
        <Workflows content={data.workflows.edges} />
        <Featured content={data.featured.edges} />
        <Security/>
        <div className="gradient">

        </div>
      </Layout>
    </GlobalStateProvider>
  )
}


export default Corporates

export const pageQuery = graphql`
  {
    corporates: allMdx(filter: { fileAbsolutePath: { regex: "/corporates/corporates/" } }) {
      edges {
        node {
          frontmatter {
            title
            url
            description
            keywords
            useSeoTitleSuffix
            useSplashScreen
          }
        }
      }
    }
    hero: allMdx(filter: { fileAbsolutePath: { regex: "/corporates/hero/" } }) {
      edges {
        node {
          frontmatter {
            greetings
            title
            subtitlePrefix
            subtitle
          }
        }
      }
    }
    clients: allMdx(filter: { fileAbsolutePath: { regex: "/corporates/clients/" } }) {
      edges {
        node {
          exports {
            shownItems
            clients {
              alt
              width
              icon
            }
          }
          frontmatter {
            title
          }
        }
      }
    }
    process: allMdx(filter: { fileAbsolutePath: { regex: "/corporates/process/" } }) {
      edges {
        node {
          frontmatter {
            title
          }
          exports {
            processes {
              name
              subtitle
              description
              icon
              tags
            }
          }
        }
      }
    }
    unify: allMdx(filter: { fileAbsolutePath: { regex: "/corporates/steps/unify/" } }) {
      edges {
        node {
          frontmatter {
            sectionTitle
            title
            description
            codeImg {
              childImageSharp {
                gatsbyImageData(width: 50, layout: FIXED, quality: 90)
              }
            }
          }
        }
      }
    }
    automate: allMdx(filter: { fileAbsolutePath: { regex: "/corporates/steps/automate/" } }) {
      edges {
        node {
          frontmatter {
            title
            description
            brainImg {
              childImageSharp {
                gatsbyImageData(width: 50, layout: FIXED, quality: 90)
              }
            }
          }
          exports {
            shownItems
            items {
              title
              text
              link
              animation
              logo {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, height: 50, quality: 90)
                }
              }
            }
          }
        }
      }
    }
    power: allMdx(filter: { fileAbsolutePath: { regex: "/corporates/steps/power/" } }) {
      edges {
        node {
          frontmatter {
            title
            description
            botImg {
              childImageSharp {
                gatsbyImageData(width: 50, layout: FIXED, quality: 90)
              }
            }
          }
        }
      }
    }
    featured: allMdx(filter: { fileAbsolutePath: { regex: "/corporates/featured/" } }) {
      edges {
        node {
          frontmatter {
            title
          }
          exports {
            usecases {
              name
              description
              cases {
                name
                tags
                icon
                type
              }
              advancedCase {
                name
                icon,
                tags
              }
            }
          }
        }
      }
    }
    useCases: allMdx(filter: { fileAbsolutePath: { regex: "/corporates/useCases/" } }) {
      edges {
        node {
          exports {
            shownItems
            cases {
              title
              description
              sources
              processing
              destinations
              poweredBy
              image {
                childImageSharp {
                  gatsbyImageData(quality: 90)
                }
              }
            }
          }
          frontmatter {
            title
            subtitle
            imageLaptop {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
          }
        }
      }
    }
    workflows: allMdx(filter: { fileAbsolutePath: { regex: "/corporates/workflows/" } }) {
      edges {
        node {
          frontmatter {
            titlePrefix1
            title1
            title2
            titlePrefix2
            title3
            titlePrefix3
            sourcesDescription
            destinationsDescription
            leftTitle
            rightTitle
            buttonText
            buttonLink
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
          }
        }
      }
    }
    footer: allMdx(filter: { fileAbsolutePath: { regex: "/layout/fr/footer/" } }) {
      edges {
        node {
          body
          frontmatter {
            label
            title
            titlePrefix
            subtitle
            subtitlePrefix
            buttonBookingText
            buttonBookingLink
            buttonSignupText
            buttonSignupLink
            copyRight
          }
          exports {
            items {
              name
              links {
                text
                link
                external
              }
            }
          }
        }
      }
    }
    header: allMdx(filter: { fileAbsolutePath: { regex: "/layout/fr/header/" } }) {
      edges {
        node {
          frontmatter {
            demoButtonText
            demoButtonLink
            registerButtonText
            registerButtonLink
            bookingButtonText
            bookingButtonLink
          }
          exports {
            items {
              name
              modal {
                left {
                  featured {
                    name
                    items {
                      title
                      description
                      link
                      icon
                      image
                    }
                  }
                  simple {
                    name
                    items {
                      title
                      link
                      icon
                      image
                    }
                  }
                }
                right {
                  featured {
                    name
                    items {
                      title
                      description
                      link
                      icon
                      image
                    }
                  }
                  simple {
                    name
                    items {
                      title
                      link
                      icon
                      image
                    }
                  }
                  media {
                    name
                    photo
                    alt
                    text
                    link
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
